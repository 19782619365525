import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/ostendi-logo.png";
import Picture from "./picture";

const Header = ({ title, link }) => (
  <header>
    <section className="header">
      <div className="header__top">
        <div className="header__logo" data-aos="fade-down">
          <a href={link} target="_blank" rel="noopener noreferrer">
            <Picture path={Logo} alt={title} size={180}/>
          </a>
        </div>
        <div className="header__nav">
          <nav data-aos="fade-down">
            <Link to="#calculator">Kalkulator</Link>
            <Link to="#ostendi">Dlaczego Ostendi</Link>
            <Link to="#reports">Przykładowe raporty</Link>
          </nav>
        </div>
      </div>
      <div className="header__title">
        <h1>Kalkulator&nbsp;oceny 360&nbsp;/&nbsp;270&nbsp;/&nbsp;180 stopni.</h1>
      </div>
    </section>
  </header>
)

Header.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
