import PropTypes from "prop-types"
import React, {useEffect, useState} from "react";
import "./CookiesInfo.scss";
import Cookies from 'universal-cookie';


const CookiesInfo = () => {
  const [showCookie, setShowCookie] = useState(true);
  const cookies = new Cookies();
  useEffect(() => {
    if (cookies.get('cookie360')) {
      setShowCookie(false)
    } else {
      setShowCookie(true)
    }
  }, [cookies]);
  const setCookie = () => {
    const date = new Date();
    const expires = new Date(date.getFullYear() + 1, date.getMonth(), date.getDay());
    cookies.set("cookie360", true, {expires});
    setShowCookie(false);
  }
  return (
    showCookie? <div className="cookies">
      <div>Strona korzysta z plików cookie. Możesz określić warunki
        przechowywania lub dostępu mechanizmu cookie w Twojej przeglądarce.
      </div>
      <button type="button" onClick={setCookie} className="cookies-btn">OK</button>
    </div>
      : ''
  )
}

export default CookiesInfo
