import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/ostendi-logo.png";
import Picture from "./picture";
import ArrowUpCircleOutlineIcon from "mdi-react/ArrowUpCircleOutlineIcon";
import FacebookIcon from "mdi-react/FacebookIcon";
import LinkedinIcon from "mdi-react/LinkedinIcon";

const Footer = ({title, link}) => (
  <footer>
    <div className="footer__logo">
      <Picture path={Logo} alt={title} size={180}/>
      <h6>People. Process. Data</h6>
    </div>
    <div className="footer__body">
      <div className="footer__col">
        Siedziba główna<br/>
        ul. Filtrowa 65/50<br/>
        02–055 Warszawa
      </div>
      <div className="footer__col">
        tel. 22 416 17 96<br/>
        fax. 22 254 52 26<br/>
        kontakt@ostendi.pl
      </div>
      <div className="footer__col">
        Wszelkie Prawa Zastrzeżone<br/>
        by Ostendi Global 2020<br/>
        <Link to="#"><FacebookIcon size={30}/></Link>
        <Link to="#"><LinkedinIcon size={30}/></Link>
      </div>
      <div className="footer__col">
        <div className="footer__gototop">
          <span><Link to="#">Przewiń w górę</Link> </span> <Link to="#"><ArrowUpCircleOutlineIcon size={30} className="mt-1"/></Link>
        </div>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
