/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header";
import Footer from "./footer";
import CookiesInfo from "./CookiesInfo";
import "./fonts.scss";
import "./layout.css";
import "./header.scss";
import "./layout-ostendi.scss";
import "./footer.scss";
import "./aos.css";
// import AOS from "aos";

const Layout = ({ children }) => {
  const isBrowser = typeof window !== "undefined";
  const AOS = isBrowser ? require("aos") : undefined;
  AOS && AOS.init();

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
        },
      }
    }
  `)

  return (
    <>
      <Header title={data.site.siteMetadata.title} link={'http://ostendi.pl'}/>
      <main>{children}</main>
      <CookiesInfo/>
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
